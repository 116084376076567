import React from "react"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: No Encontrado" />
    <div className="section-404">
      <div className="div-404">
        <div className="title-404">
          Error 404
          <br />
          <e className="text-404">
            Lo sentimos, No encontramos lo que estas buscando!
          </e>
        </div>
      </div>
    </div>
  </>
)

export default NotFoundPage
